import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
  IECertificate,
  udyam,
  shopAct,
  GST1,
  GST2,
  GST3,
  RCMC1,
  RCMC2,
  ADCODE,
  saleImgOne,
  saleImgTwo,
  saleImgThree,

  basmatirice,
  blackrice,
  brownrice,
  glutinousrice,
  longgrain,
  mediumgrain,
  parboiledrice,
  redrice,
  shortgrain,
  wildrice,

  groundnuts,
  walnuts,

  turmeric,
  dry,
  ground,
  row,

  corianderpowderleaf,
  corinaderpowder,
  corinaderleaves,

  kashmirichillipwdr,
  redchillipowder,
  dryredchilli,

  cuminseed,
  cuminPowder,
  blackcumin,
  cumintoastedwhole,
  cumminwater,
  groundcumin,
  wholecuminseeds,

  barley,
  buckwheat,
  greatmillet,
  maize,
  oat,
  rye,
  wheat,

} from "../assets/images/index";


// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Home",
    link: "/",
  },
  {
    _id: 1002,
    title: "Trade",
    link: "/trade",
  },
  {
    _id: 1003,
    title: "About Us",
    link: "/about",
  },
  {
    _id: 1004,
    title: "Contact Us",
    link: "contact",
  }
  // {
  //   _id: 1005,
  //   title: "Certificates",
  //   link: "/certificate",
  // }
];
// =================== NavBarList End here ======================
// =================== Special Offer data Start here ============
export const SplOfferData = [
  {
    _id: "201",
    img: spfOne,
    productName: "Red Onions",
    price: "3.03",
    color: "Dark Red",
    badge: true,
    packaging: "1 kg, 2 kg, 5 kg, 10 kg, 20 kg, Mesh Bag",
    size: "30 mm to 50 mm and 50 mm to 60 mm",
    des: "Fresh Onions Exporters from India"
  },
  {
    _id: "202",
    img: newArrFour,
    productName: "Red Chillies",
    price: "60.00",
    color: "Mixed",
    badge: false,
    size: "5 to 10 cm",
    packaging: "3 kg to 5 kg Carton Box",
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: "203",
    img: spfThree,
    productName: "Cucumber ",
    price: "3.00",
    color: "Green",
    badge: true,
    des: "Cucumbers are low in calories and rich in water, making them hydrating. They also contain vitamins such as vitamin K and vitamin C, as well as minerals like potassium.",
    Taste: "Cucumbers have a mild, refreshing taste.",
    Varities: "English cucumbers, Persian cucumbers, and standard American slicing cucumbers.",
    size: "6 to 9 inches in length",
  },
  {
    _id: "204",
    img: spfFour,
    productName: "Mixed Chillies",
    price: "220.00",
    color: "Green and Red",
    badge: false,
    size: "5 to 10 cm",
    packaging: "3 kg to 5 kg Carton Box",
    des: "Fresh Green Chilies Exporters from India"
  },
];
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

export const paginationItems = [
  {
    _id: 1001,
    img: spfOne,
    productName: "Red Onions ",
    price: "3.03",
    color: "Red",
    badge: true,
    des: "Fresh Onions Exporters from India",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "Carrot",
    price: "3.92",
    color: "Orange",
    badge: true,
    des: "Orange carrots are rich in beta-carotene, an antioxidant that the body converts into vitamin A. They also contain other essential nutrients such as vitamin K, vitamin C, potassium, and dietary fiber.",
  },
  {
    _id: 1003,
    img: spfThree,
    productName: "Cucumber",
    price: "25.00",
    color: "Green",
    badge: true,
    des: "Cucumbers are low in calories and rich in water, making them hydrating. They also contain vitamins such as vitamin K and vitamin C, as well as minerals like potassium.",
  },
  {
    _id: 1004,
    img: spfFour,
    productName: "Mixed Chillies",
    price: "220.00",
    color: "Green and Red",
    badge: true,
    des: "Fresh Green Chilies Exporters from India",
  },
  {
    _id: 1005,
    img: bestSellerOne,
    productName: "Fresh Red Tomatoes",
    price: "04.00",
    color: "Red",
    badge: true,
    des: "Fresh Red Tomatoes Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",
  },
  {
    _id: 1006,
    img: bestSellerTwo,
    productName: "Yellow Lemon",
    price: "1.73",
    color: "Yellow",
    badge: false,
    des: "Fresh Yellow Lemons Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",
  },
  {
    _id: 1007,
    img: bestSellerThree,
    productName: "Green Lemon",
    price: "1.00",
    color: "Green",
    badge: true,
    des: "Fresh Lemons Lemon Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",
  },
  {
    _id: 1008,
    img: bestSellerFour,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",
  },
  {
    _id: 1009,
    img: newArrOne,
    productName: "Red Onions",
    price: "3.03",
    color: "Red",
    badge: true,
    des: "Fresh Onions Exporters from India",
  },
  {
    _id: 1010,
    img: newArrTwo,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: true,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "Mixed Onion Red",
    price: "2.03",
    color: "Mixed",
    badge: true,
    des: "Fresh Onions Exporters from India "
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "Red Chillies",
    price: "60.00",
    color: "Mixed",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"

  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },

  {
    _id: 1014,
    img: newArrTwo,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "Red Chillies",
    price: "60.00",
    color: "Mixed",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "Red Onions",
    price: "3.03",
    color: "Red",
    badge: true,
    des: "Fresh Onions Exporters from India",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "Mixed Onion Red",
    price: "2.03",
    color: "Mixed",
    badge: true,
    des: "Fresh Onions Exporters from India "
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "Cucumber",
    price: "25.00",
    color: "Green",
    badge: true,
    des: "Cucumbers are low in calories and rich in water, making them hydrating. They also contain vitamins such as vitamin K and vitamin C, as well as minerals like potassium.",

  },
  {
    _id: 1022,
    img: spfFour,
    productName: "Mixed Chillies",
    price: "220.00",
    color: "Green and Red",
    badge: true,
    des: "Fresh Green Chilies Exporters from India",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "Fresh Red Tomatoes",
    price: "04.00",
    color: "Red",
    badge: true,
    des: "Fresh Red Tomatoes Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1024,
    img: spfOne,
    productName: "Red Onions ",
    price: "3.03",
    color: "Red",
    badge: true,
    des: "Fresh Onions Exporters from India",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "Carrot",
    price: "3.92",
    color: "Orange",
    badge: true,
    des: "Orange carrots are rich in beta-carotene, an antioxidant that the body converts into vitamin A. They also contain other essential nutrients such as vitamin K, vitamin C, potassium, and dietary fiber.",

  },
  {
    _id: 1026,
    img: spfThree,
    productName: "Cucumber",
    price: "25.00",
    color: "Green",
    badge: true,
    des: "Cucumbers are low in calories and rich in water, making them hydrating. They also contain vitamins such as vitamin K and vitamin C, as well as minerals like potassium.",

  },
  {
    _id: 1027,
    img: spfFour,
    productName: "Mixed Chillies",
    price: "220.00",
    color: "Green and Red",
    badge: true,
    des: "Fresh Green Chilies Exporters from India",
  },
  {
    _id: 1028,
    img: bestSellerOne,
    productName: "Fresh Red Tomatoes",
    price: "04.00",
    color: "Red",
    badge: true,
    des: "Fresh Red Tomatoes Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1029,
    img: bestSellerTwo,
    productName: "Yellow Lemon",
    price: "1.73",
    color: "Yellow",
    badge: false,
    des: "Fresh Yellow Lemons Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1030,
    img: bestSellerThree,
    productName: "Green Lemon",
    price: "1.00",
    color: "Green",
    badge: true,
    des: "Fresh Lemons Lemon Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",
  },
  {
    _id: 1031,
    img: bestSellerFour,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1032,
    img: newArrOne,
    productName: "Red Onions",
    price: "3.03",
    color: "Red",
    badge: true,
    des: "Fresh Onions Exporters from India",
  },
  {
    _id: 1033,
    img: newArrTwo,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1034,
    img: newArrThree,
    productName: "Mixed Onion Red",
    price: "2.03",
    color: "Mixed",
    badge: true,
    des: "Fresh Onions Exporters from India "
  },
  {
    _id: 1035,
    img: newArrFour,
    productName: "Red Chillies",
    price: "60.00",
    color: "Mixed",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1036,
    img: newArrTwo,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1037,
    img: newArrFour,
    productName: "Red Chillies",
    price: "60.00",
    color: "Mixed",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1038,
    img: newArrTwo,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1039,
    img: bestSellerFour,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1040,
    img: newArrOne,
    productName: "Red Onions",
    price: "3.03",
    color: "Red",
    badge: true,
    des: "Fresh Onions Exporters from India",
  },
  {
    _id: 1041,
    img: newArrTwo,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1042,
    img: newArrThree,
    productName: "Mixed Onion Red",
    price: "2.03",
    color: "Mixed",
    badge: true,
    des: "Fresh Onions Exporters from India "
  },
  {
    _id: 1043,
    img: spfThree,
    productName: "Cucumber",
    price: "25.00",
    color: "Green",
    badge: true,
    des: "Cucumbers are low in calories and rich in water, making them hydrating. They also contain vitamins such as vitamin K and vitamin C, as well as minerals like potassium.",

  },
  {
    _id: 1044,
    img: spfFour,
    productName: "Mixed Chillies",
    price: "220.00",
    color: "Green and Red",
    badge: true,
    des: "Fresh Green Chilies Exporters from India",
  },
  {
    _id: 1045,
    img: bestSellerOne,
    productName: "Fresh Red Tomatoes",
    price: "04.00",
    color: "Red",
    badge: true,
    des: "Fresh Red Tomatoes Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1046,
    img: spfOne,
    productName: "Red Onions ",
    price: "3.03",
    color: "Red",
    badge: true,
    des: "Fresh Onions Exporters from India",
  },
];

export const certificateItems = [
  {
    _id: 1001,
    img: spfOne,
    productName: "Red Onions ",
    price: "3.03",
    color: "Red",
    badge: true,
    des: "Fresh Onions Exporters from India",
  },
  {
    _id: 1002,
    img: spfTwo,
    productName: "Carrot",
    price: "3.92",
    color: "Orange",
    badge: true,
    des: "Orange carrots are rich in beta-carotene, an antioxidant that the body converts into vitamin A. They also contain other essential nutrients such as vitamin K, vitamin C, potassium, and dietary fiber.",

  },
  {
    _id: 1003,
    img: spfThree,
    productName: "Cucumber",
    price: "25.00",
    color: "Green",
    badge: true,
    des: "Cucumbers are low in calories and rich in water, making them hydrating. They also contain vitamins such as vitamin K and vitamin C, as well as minerals like potassium.",

  },
  {
    _id: 1004,
    img: spfFour,
    productName: "Mixed Chillies",
    price: "220.00",
    color: "Green and Red",
    badge: true,
    des: "Fresh Green Chilies Exporters from India",
  },
  {
    _id: 1005,
    img: bestSellerOne,
    productName: "Fresh Red Tomatoes",
    price: "04.00",
    color: "Red",
    badge: true,
    des: "Fresh Red Tomatoes Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1006,
    img: bestSellerTwo,
    productName: "Yellow Lemon",
    price: "1.73",
    color: "Yellow",
    badge: false,
    des: "Fresh Yellow Lemons Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1007,
    img: bestSellerThree,
    productName: "Green Lemon",
    price: "1.00",
    color: "Green",
    badge: true,
    des: "Fresh Lemons Lemon Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1008,
    img: bestSellerFour,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1009,
    img: newArrOne,
    productName: "Red Onions",
    price: "3.03",
    color: "Red",
    badge: true,
    des: "Fresh Onions Exporters from India",
  },
  {
    _id: 1010,
    img: newArrTwo,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1011,
    img: newArrThree,
    productName: "Mixed Onion Red",
    price: "2.03",
    color: "Mixed",
    badge: true,
    des: "Fresh Onions Exporters from India "
  },
  {
    _id: 1012,
    img: newArrFour,
    productName: "Red Chillies",
    price: "60.00",
    color: "Mixed",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1013,
    img: newArrTwo,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },

  {
    _id: 1014,
    img: newArrTwo,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1015,
    img: newArrFour,
    productName: "Red Chillies",
    price: "60.00",
    color: "Mixed",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1016,
    img: newArrTwo,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1017,
    img: bestSellerFour,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1018,
    img: newArrOne,
    productName: "Red Onions",
    price: "3.03",
    color: "Red",
    badge: true,
    des: "Fresh Onions Exporters from India",
  },
  {
    _id: 1019,
    img: newArrTwo,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1020,
    img: newArrThree,
    productName: "Mixed Onion Red",
    price: "2.03",
    color: "Mixed",
    badge: true,
    des: "Fresh Onions Exporters from India "
  },
  {
    _id: 1021,
    img: spfThree,
    productName: "Cucumber",
    price: "25.00",
    color: "Green",
    badge: true,
    des: "Cucumbers are low in calories and rich in water, making them hydrating. They also contain vitamins such as vitamin K and vitamin C, as well as minerals like potassium.",

  },
  {
    _id: 1022,
    img: spfFour,
    productName: "Mixed Chillies",
    price: "220.00",
    color: "Green and Red",
    badge: true,
    des: "Fresh Green Chilies Exporters from India",
  },
  {
    _id: 1023,
    img: bestSellerOne,
    productName: "Fresh Red Tomatoes",
    price: "04.00",
    color: "Red",
    badge: true,
    des: "Fresh Red Tomatoes Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1024,
    img: spfOne,
    productName: "Red Onions ",
    price: "3.03",
    color: "Red",
    badge: true,
    des: "Fresh Onions Exporters from India",
  },
  {
    _id: 1025,
    img: spfTwo,
    productName: "Carrot",
    price: "3.92",
    color: "Orange",
    badge: true,
    des: "Orange carrots are rich in beta-carotene, an antioxidant that the body converts into vitamin A. They also contain other essential nutrients such as vitamin K, vitamin C, potassium, and dietary fiber.",

  },
  {
    _id: 1026,
    img: spfThree,
    productName: "Cucumber",
    price: "25.00",
    color: "Green",
    badge: true,
    des: "Cucumbers are low in calories and rich in water, making them hydrating. They also contain vitamins such as vitamin K and vitamin C, as well as minerals like potassium.",

  },
  {
    _id: 1027,
    img: spfFour,
    productName: "Mixed Chillies",
    price: "220.00",
    color: "Green and Red",
    badge: true,
    des: "Fresh Green Chilies Exporters from India",
  },
  {
    _id: 1028,
    img: bestSellerOne,
    productName: "Fresh Red Tomatoes",
    price: "04.00",
    color: "Red",
    badge: true,
    des: "Fresh Red Tomatoes Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1029,
    img: bestSellerTwo,
    productName: "Yellow Lemon",
    price: "1.73",
    color: "Yellow",
    badge: false,
    des: "Fresh Yellow Lemons Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1030,
    img: bestSellerThree,
    productName: "Green Lemon",
    price: "1.00",
    color: "Green",
    badge: true,
    des: "Fresh Lemons Lemon Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1031,
    img: bestSellerFour,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1032,
    img: newArrOne,
    productName: "Red Onions",
    price: "3.03",
    color: "Red",
    badge: true,
    des: "Fresh Onions Exporters from India",
  },
  {
    _id: 1033,
    img: newArrTwo,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1034,
    img: newArrThree,
    productName: "Mixed Onion Red",
    price: "2.03",
    color: "Mixed",
    badge: true,
    des: "Fresh Onions Exporters from India "
  },
  {
    _id: 1035,
    img: newArrFour,
    productName: "Red Chillies",
    price: "60.00",
    color: "Mixed",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1036,
    img: newArrTwo,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1037,
    img: newArrFour,
    productName: "Red Chillies",
    price: "60.00",
    color: "Mixed",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1038,
    img: newArrTwo,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1039,
    img: bestSellerFour,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1040,
    img: newArrOne,
    productName: "Red Onions",
    price: "3.03",
    color: "Red",
    badge: true,
    des: "Fresh Onions Exporters from India",
  },
  {
    _id: 1041,
    img: newArrTwo,
    productName: "Fresh Green Chillies",
    price: "3.70",
    color: "Green",
    badge: false,
    des: "Fresh Green Chilies Exporters from India"
  },
  {
    _id: 1042,
    img: newArrThree,
    productName: "Mixed Onion Red",
    price: "2.03",
    color: "Mixed",
    badge: true,
    des: "Fresh Onions Exporters from India "
  },
  {
    _id: 1043,
    img: spfThree,
    productName: "Cucumber",
    price: "25.00",
    color: "Green",
    badge: true,
    des: "Cucumbers are low in calories and rich in water, making them hydrating. They also contain vitamins such as vitamin K and vitamin C, as well as minerals like potassium.",

  },
  {
    _id: 1044,
    img: spfFour,
    productName: "Mixed Chillies",
    price: "220.00",
    color: "Green and Red",
    badge: true,
    des: "Fresh Green Chilies Exporters from India",
  },
  {
    _id: 1045,
    img: bestSellerOne,
    productName: "Fresh Red Tomatoes",
    price: "04.00",
    color: "Red",
    badge: true,
    des: "Fresh Red Tomatoes Exporters from India also provide Pomegranate Import at Sri Lanka, Maldives, Oman ,South Africa and all over world.",

  },
  {
    _id: 1046,
    img: spfOne,
    productName: "Red Onions ",
    price: "3.03",
    color: "Red",
    badge: true,
    des: "Fresh Onions Exporters from India",
  },
];

export const certificates = [
  {
    _id: 1001,
    img: IECertificate,
    productName: "IE Certificate",
    price: "www.dgft.gov.in",
    color: "BXAPK7336P",
  },
  {
    _id: 1002,
    img: udyam,
    productName: "Udyam",
    price: "www.udyamregistration.gov.in",
    color: "UDYAM-MH-26-0547468",

  }, {
    _id: 1003,
    img: shopAct,
    productName: "Shop Act",
    price: "aaplesarkar.mahaonline.gov.in",
    color: "2331000318234111",
  },
  {
    _id: 1004,
    img: GST1,
    productName: "GST Page1",
    price: "services.gst.gov.in",
    color: "27BXAPK7336P1ZX",
  },
  {
    _id: 1005,
    img: GST2,
    productName: "GST Page2",
    price: "services.gst.gov.in",
    color: "27BXAPK7336P1ZX",
  },
  {
    _id: 1006,
    img: GST3,
    productName: "GST Page3",
    price: "services.gst.gov.in",
    color: "27BXAPK7336P1ZX",
  }, {
    _id: 1007,
    img: RCMC1,
    productName: "RCMC Page1",
    price: "apeda.gov.in",
    color: "RCMC/APEDA/03323/2023-2024",
  }, {
    _id: 1008,
    img: RCMC2,
    productName: "RCMC Page2",
    price: "apeda.gov.in",
    color: "RCMC/APEDA/03323/2023-2024",
  }, {
    _id: 1009,
    img: ADCODE,
    productName: "AD Code",
    price: "www.icicibank.com",
    color: "6392459-6200804",
  },
];


// =================== PaginationItems End here =================
export const currentSales = [
  {
    _id: 1001,
    img: saleImgOne,
    productName: "Rice",
    price: "",
    color: "",
    des: "Rice is a versatile and staple food that has been a dietary mainstay for a significant portion of the world's population for thousands of years. \n \n Premium quality rice, cultivated and harvested with care for a delightful culinary experience.\n Consuming rice can offer several health benefits - Energy Source, Low in Fat and Cholesterol-Free, Dietary Fiber, Nutrient Content, Gluten-Free, Blood Sugar Regulation and Versatility in Cooking",
    speci: [
      {
        _id: 101,
        _pur: "Purity",
        Purity: "96.00%",
        _Natural: "Natural Admixture",
        Natural_Adm: "4.00%",
        _avg: "Average Grain Length",
        AverageGrain: "8.35 MM",
        _Moisture: "Moisture",
        Moisture: "12.5% Max.",
        _BrokenGrain: "Broken Grain",
        BrokenGrain: " 1% Max",
        _DamageGrain: "Damage/Discolour Grain",
        DamageGrain: "1% Max",
        _ImmatureGrain: "Immature Grain",
        ImmatureGrain: "1% Max.",
        _ForeignMatter: "Foreign Matter",
        ForeignMatter: "Nil",
        _PackagingType: "Packaging Type",
        PackagingType: "Jute bag, PP bag, Non-woven bag or as per buyer's choice.",
        _SupplyAbility: "Supply Ability",
        SupplyAbility: "2000 tons Per Week",
        _MainExportMarket: "Main Export Market(S)",
        MainExportMarket: "Europe, USA, Australia, Saudi Arabia, Kuwait, Egypt, UAE, Jordan, Oman, Bahrain, Yemen, Syria, Iraq, Libya, Turkey",
        _DeliveryTime: "Delivery Time",
        DeliveryTime: "Approximately  15 Days"
      }
    ],
    categ: [
      {
        _id: 2001,
        img: longgrain,
        productName: "Long-Grain Rice",
        types: [{
          _id: 3001,
          productName: "Basmati Rice",
          des: "Known for its distinctive fragrance, Basmati rice is a long-grain variety that is commonly used in Indian and Middle Eastern cuisine. It has a light, fluffy texture and a nutty aroma."
        },
        {
          _id: 3002,
          productName: "Jasmine Rice",
          des: "A fragrant long-grain rice variety, Jasmine rice is popular in Southeast Asian cuisine, particularly in Thai dishes. It has a slightly sticky texture when cooked and a floral aroma."
        },
        {
          _id: 3003,
          productName: "American Long-Grain Rice",
          des: "Commonly grown in the United States, this rice is versatile and often used in a variety of dishes. It includes popular varieties like Carolina Long Grain and Delta Blues."
        },
        {
          _id: 3004,
          productName: "Texmati Rice",
          des: "A type of Basmati rice grown in Texas, Texmati has a similar aroma and texture to traditional Basmati rice. It is often used in various cuisines."
        },
        {
          _id: 3005,
          productName: "Indian Long-Grain Rice",
          des: "Apart from Basmati, there are other long-grain rice varieties grown in India, including Patna rice and Dehradun rice, each with its own characteristics."
        },
        {
          _id: 3006,
          productName: "Hom Mali Rice (Thai Hom Mali)",
          des: "Also known as fragrant rice, this is a long-grain rice variety grown in Thailand. It is recognized for its fragrant aroma and slightly sticky texture."
        },
        {
          _id: 3007,
          productName: "Mahatma Rice",
          des: "Mahatma is a brand of rice that produces long-grain varieties, including regular long-grain rice and extra-long-grain rice."
        },
        {
          _id: 3008,
          productName: "Wehani Rice",
          des: "Though known for its red-brown color, Wehani rice is a long-grain variety with a nutty flavor. It is often used in salads and side dishes."
        },
        {
          _id: 3009,
          productName: "Maharaja Rice",
          des: "A long-grain rice variety that is popular in Indian cuisine, known for its length and versatility in different dishes."
        },

        ],
        des: "Long-grain rice, such as Basmati or Jasmine, is characterized by slender grains that stay separate and fluffy when cooked, offering a delicate texture and a distinctive aromatic quality ideal for various international cuisines.",
      },
      {
        _id: 2002,
        img: mediumgrain,
        productName: "Medium-Grain Rice",
        types: [{
          _id: 3001,
          productName: "Calrose Rice",
          des: "Calrose is a popular medium-grain rice variety, especially in the United States. It is known for its slightly sticky texture when cooked and is often used in Asian cuisine, including sushi and rice bowls."
        },
        {
          _id: 3002,
          productName: "Japonica Rice",
          des: "Japonica rice includes various short-grain and medium-grain varieties commonly used in Japanese cuisine. Examples include Koshihikari and Tamaki Gold."
        },
        {
          _id: 3003,
          productName: "Arborio Rice",
          des: "Although Arborio is often considered short-grain, it falls into the medium-grain category. It is commonly used in Italian dishes, especially for making risotto."
        },
        {
          _id: 3004,
          productName: "Carnaroli Rice",
          des: "Similar to Arborio, Carnaroli rice is another medium-grain rice variety used in making risotto. It has a higher starch content, resulting in a creamy texture."
        },
        {
          _id: 3005,
          productName: "Bomba Rice",
          des: "Originating from Spain, Bomba rice is a short to medium-grain variety. It is known for its ability to absorb liquids well, making it ideal for paella."
        },
        {
          _id: 3006,
          productName: "Balilla Rice",
          des: "A medium-grain rice variety grown in Italy, Balilla rice is often used in Mediterranean cuisine for dishes like paella and rice salads."
        },
        {
          _id: 3007,
          productName: "Valencia Rice",
          des: "Commonly grown in Spain, Valencia rice is a medium-grain variety known for its absorption of flavors. It is often used in paella."
        },
        {
          _id: 3008,
          productName: "Sona Masuri",
          des: "Originating from India, Sona Masuri is a medium-grain rice with a fragrant aroma. It is widely used in Indian cuisine for dishes like biryani."
        }],
        des: "Medium-grain rice, like Arborio or Calrose, features grains that are slightly shorter and plumper than long-grain varieties. Known for a tender and slightly sticky texture when cooked, it is often favored in dishes like risotto or sushi, providing a versatile and absorbent base for various culinary creations.",
      },
      {
        _id: 2003,
        img: shortgrain,
        productName: "Short-Grain Rice",
        types: [{
          _id: 3001,
          productName: "Arborio Rice",
          des: "Originating from Italy, Arborio rice is commonly used in risotto due to its high starch content, which gives the dish a creamy texture."
        },
        {
          _id: 3002,
          productName: "Carnaroli Rice",
          des: "Another Italian short-grain rice variety, Carnaroli rice, is often used in making risotto as well. It is known for its ability to absorb flavors while maintaining a firm texture."
        },
        {
          _id: 3003,
          productName: "Sushi Rice (Shari or Sumeshi)",
          des: "Specially cultivated for making sushi, sushi rice is a short-grain rice variety that becomes sticky when cooked. It is seasoned with a mixture of rice vinegar, sugar, and salt after cooking."
        },
        {
          _id: 3004,
          productName: "Calrose Rice",
          des: "Widely used in Asian cuisine, especially in the United States, Calrose rice is a medium-grain variety that is often considered short-grain. It is commonly used in dishes like sushi and rice bowls."
        },
        {
          _id: 3005,
          productName: "Japonica Rice",
          des: "Japonica is a type of rice that includes various short-grain and medium-grain varieties commonly used in Japanese cuisine. Examples include Koshihikari and Tamaki Gold."
        },
        {
          _id: 3006,
          productName: "Baldo Rice",
          des: "This is a short-grain rice variety, primarily cultivated in Italy. It is often used in risotto and other Mediterranean dishes."
        },
        {
          _id: 3007,
          productName: "Bomba Rice",
          des: "Originating from Spain, Bomba rice is a short-grain rice variety known for its ability to absorb liquids and flavors well, making it ideal for paella."
        },
        {
          _id: 3008,
          productName: "Pearl Rice",
          des: "This short-grain rice variety is often used in Southeast Asian cuisine and is known for its sticky texture, making it suitable for desserts."
        },
        {
          _id: 3009,
          productName: "Sticky Rice (Glutinous Rice)",
          des: "While not technically a short-grain rice, sticky rice is often categorized as such due to its short and glutinous nature. It is commonly used in Asian desserts and savory dishes."
        },

        ],
        des: "Short-grain rice, exemplified by varieties like Sushi or Bomba, is characterized by its round and plump grains. Renowned for its sticky and chewy texture when cooked, it is a staple in sushi and paella, contributing to a delightful mouthfeel and cohesion in various dishes",
      },
      {
        _id: 2004,
        img: brownrice,
        productName: "Brown Rice",
        types: [{
          _id: 3001,
          productName: "Long-Grain Brown Rice",
          des: "This type has long, slender grains and remains separate and fluffy when cooked. It is versatile and can be used in various dishes."
        },
        {
          _id: 3002,
          productName: "Medium-Grain Brown Rice",
          des: "Medium-grain brown rice has grains that are shorter and plumper compared to long-grain varieties. It tends to be slightly more tender and sticky when cooked, making it suitable for dishes where a stickier texture is desired."
        },
        {
          _id: 3003,
          productName: "Short-Grain Brown Rice",
          des: "Short-grain brown rice has rounder, shorter grains and tends to be more starchy and sticky when cooked. It is often used in Asian dishes, such as sushi."
        },
        {
          _id: 3004,
          productName: "Basmati Brown Rice",
          des: "A long-grain brown rice with the aromatic qualities of traditional Basmati rice. It has a nutty flavor and fragrant aroma."
        },
        {
          _id: 3005,
          productName: "Jasmine Brown Rice",
          des: "Similar to Basmati brown rice, Jasmine brown rice has a fragrant aroma and slightly sticky texture when cooked. It is commonly used in Asian cuisine."
        },
        {
          _id: 3006,
          productName: "Black Rice (Forbidden Rice)",
          des: "While not technically a type of brown rice, black rice is a whole grain rice with a dark bran layer. It has a nutty flavor and is rich in antioxidants."
        },
        {
          _id: 3007,
          productName: "Red Rice",
          des: "Red rice is another whole grain rice with a reddish bran layer. It has a slightly nutty taste and a chewy texture."
        },
        {
          _id: 3008,
          productName: "Wehani Rice",
          des: "A type of aromatic brown rice with a reddish-brown color. It has a nutty flavor and retains its color when cooked."
        }],
        des: "Brown rice, whether long or short grain, is a whole grain rice that retains its bran and germ layers, imparting a nutty flavor and a chewy texture. Recognized for its higher nutritional value, it offers a wholesome alternative with increased fiber, vitamins, and minerals compared to white rice.",
      },
      {
        _id: 2005,
        img: wildrice,
        productName: "Wild Rice",
        types: [{
          _id: 3001,
          productName: "Northern Wild Rice (Zizania palustris)",
          des: "Also known as Canadian wild rice, this is the most common and widely recognized type. It has a dark color, long grains, and a robust, nutty flavor. This is the variety often found in stores."
        },
        {
          _id: 3002,
          productName: "Manchurian Wild Rice (Zizania latifolia)",
          des: "Native to Asia, particularly China, this type of wild rice has slightly shorter grains and is cultivated in paddies. It has a milder flavor compared to the Northern wild rice."
        },
        {
          _id: 3003,
          productName: "Texas Wild Rice (Zizania texana)",
          des: "Endemic to a limited area in the San Marcos River in Texas, this wild rice has shorter grains and is considered a rare and endangered species."
        },
        {
          _id: 3004,
          productName: "Wild Rice Blends",
          des: "Some commercially available wild rice blends may combine true wild rice with other rice varieties to create a mix with varied colors, textures, and flavors."
        }
        ],
        des: "Wild rice, technically a grass seed rather than true rice, is characterized by its long, slender grains with a dark, almost black hue. Known for its earthy, nutty flavor and chewy texture, wild rice is rich in nutrients and often used in a variety of culinary applications, adding depth to dishes and salads.",
      },
      {
        _id: 2006,
        img: parboiledrice,
        productName: "Parboiled Rice",
        types: [{
          _id: 3001,
          productName: "Parboiled Long-Grain Rice",
          des: "This type of parboiled rice has long grains and is popular for its fluffy texture when cooked. It is commonly used in various dishes."
        },
        {
          _id: 3002,
          productName: "Parboiled Medium-Grain Rice",
          des: "Similar to long-grain parboiled rice, the medium-grain variety retains its shape well and has a slightly sticky texture when cooked."
        },
        {
          _id: 3003,
          productName: "Parboiled Short-Grain Rice",
          des: "This type of parboiled rice has short grains and tends to be more glutinous, making it suitable for dishes that require a sticky texture."
        },
        {
          _id: 3004,
          productName: "Converted Rice",
          des: "Sometimes used interchangeably with parboiled rice, converted rice has undergone a similar parboiling process, and the terms are often used to describe the same product."
        },
        {
          _id: 3005,
          productName: "Instant Parboiled Rice",
          des: "This is a quick-cooking version of parboiled rice that has been pre-cooked and dehydrated. It requires less cooking time than traditional parboiled rice."
        },
        {
          _id: 3006,
          productName: "Brown Parboiled Rice",
          des: "Similar to regular brown rice, brown parboiled rice retains more nutrients due to the parboiling process. It has a nuttier flavor and chewier texture compared to white parboiled rice."
        },
        {
          _id: 3007,
          productName: "Quick-Cook Parboiled Rice",
          des: "This type of parboiled rice is processed to cook faster than traditional parboiled rice, making it convenient for quick meals."
        },
        {
          _id: 3008,
          productName: "Organic Parboiled Rice",
          des: "Produced using organic farming methods, organic parboiled rice undergoes the parboiling process without the use of synthetic pesticides or fertilizers."
        }
        ],
        des: "Parboiled rice is rice that has been partially boiled in the husk, a process that drives nutrients from the outer layers to the endosperm. This results in firmer, separate grains when cooked, offering a nutritious and versatile option that maintains a golden color and provides a unique texture in a variety of dishes.",
      },
      {
        _id: 2007,
        img: glutinousrice,
        productName: "Glutinous Rice (Sticky Rice)",
        types: [{
          _id: 3001,
          productName: "Japanese Mochi Rice",
          des: "Used to make traditional Japanese mochi, this short-grain glutinous rice has a high starch content, creating a chewy and sticky texture."
        },
        {
          _id: 3002,
          productName: "Thai Sweet Rice",
          des: "Commonly used in Thai cuisine for desserts and sticky rice dishes, Thai sweet rice has a slightly sweet flavor and becomes sticky when cooked."
        },
        {
          _id: 3003,
          productName: "Chinese Glutinous Rice",
          des: "Widely used in Chinese cuisine, this short-grain rice is sticky when cooked and is often used in both savory and sweet dishes."
        },
        {
          _id: 3004,
          productName: "Korean Sweet Rice (Chapssal)",
          des: "Essential for making Korean rice cakes (tteok), chapssal has a sticky texture and is used in various traditional Korean desserts."
        },
        {
          _id: 3005,
          productName: "Indonesian Ketan",
          des: "Ketan is a type of sticky rice commonly used in Indonesian cuisine. It is often used in traditional sweets and snacks."
        },
        {
          _id: 3006,
          productName: "Vietnamese Sticky Rice (Nep)",
          des: "Nep is a glutinous rice variety used in Vietnamese cuisine for dishes like xôi (sticky rice) and various desserts."
        },
        {
          _id: 3007,
          productName: "Filipino Malagkit",
          des: "Malagkit is the Filipino term for glutinous rice. It is used in various Filipino kakanin (rice cakes) and desserts."
        },
        {
          _id: 3008,
          productName: "Taiwanese Sticky Rice",
          des: "Used in Taiwanese cuisine, this glutinous rice is often found in traditional snacks and desserts."
        },
        {
          _id: 3009,
          productName: "Laotian Khao Niew",
          des: "Khao Niew is the Laotian term for sticky rice, which is a staple in Laotian cuisine. It is typically served with various dishes."
        },
        {
          _id: 3010,
          productName: "Burmese Sticky Rice",
          des: "Glutinous rice is used in Burmese cuisine for making traditional sweets and snacks."
        }
        ],
        des: "Glutinous rice, commonly known as sticky rice, is a type of rice with a higher starch content that imparts a sticky and chewy texture when cooked. Popular in Asian cuisines, it is often used in both savory and sweet dishes, such as dumplings and desserts, contributing to a delightful and cohesive consistency.",
      },
      {
        _id: 2008,
        img: redrice,
        productName: "Red Rice",
        types: [{
          _id: 3001,
          productName: "Camargue Red Rice",
          des: "Grown in the Camargue region of France, this red rice has a distinctive nutty flavor and is often used in salads and side dishes."
        },
        {
          _id: 3002,
          productName: "Bhutanese Red Rice",
          des: "Cultivated in Bhutan, this rice has a red bran layer and a slightly nutty taste. It is often used in traditional Bhutanese dishes."
        },
        {
          _id: 3003,
          productName: "Thai Red Cargo Rice",
          des: "A whole grain rice variety from Thailand, it has a reddish-brown bran layer and a nutty flavor. It is often used in both savory and sweet dishes."
        },
        {
          _id: 3004,
          productName: "Mattamuskeet Red",
          des: "Grown in the United States, particularly in North Carolina, Mattamuskeet Red rice has a reddish-brown bran layer and a distinct, rich flavor."
        },
        {
          _id: 3005,
          productName: "Wehani Rice",
          des: "Although not truly red, Wehani rice has a russet-colored bran layer and turns a reddish-brown when cooked. It has a nutty and chewy texture."
        },
        {
          _id: 3006,
          productName: "Himalayan Red Rice",
          des: "This rice is cultivated in the Himalayan region and has a red bran layer. It has a nutty flavor and a chewy texture."
        },
        {
          _id: 3007,
          productName: "Red Jasmine Rice",
          des: "A type of red rice that has a similar aromatic quality to traditional jasmine rice. It is often used in Asian cuisine."
        },
        {
          _id: 3008,
          productName: "Red Camargue Rice",
          des: "Similar to Camargue Red Rice, this rice is grown in the Camargue region of France and has a nutty flavor and chewy texture."
        }
        ],
        des: "Red rice is a whole grain rice variety known for its distinctive reddish-brown color, attributed to the presence of its outer bran layer. With a nutty flavor and chewy texture, red rice is rich in antioxidants and nutrients, offering a wholesome and flavorful alternative to white rice in various culinary creations.",
      },
      {
        _id: 2009,
        img: blackrice,
        productName: "Black Rice (Forbidden Rice)",
        types: [{
          _id: 3001,
          productName: "Chinese Black Rice",
          des: "This is a medium-grain black rice that turns a deep purple when cooked. It has a sweet flavor and a sticky texture, making it suitable for both sweet and savory dishes."
        },
        {
          _id: 3002,
          productName: "Thai Black Jasmine Rice",
          des: "A fragrant black rice with a nutty flavor, it is often used in Thai cuisine. It retains its dark color even after cooking."
        },
        {
          _id: 3003,
          productName: "Indonesian Black Rice",
          des: "Grown in Indonesia, this variety is known for its deep black color and nutty taste. It is often used in traditional Indonesian desserts."
        },
        {
          _id: 3004,
          productName: "Bhutanese Black Rice",
          des: "Cultivated in Bhutan, this rice has a dark color and a slightly sticky texture when cooked. It is used in various Bhutanese dishes."
        },
        {
          _id: 3005,
          productName: "Philippine Black Rice (Tapol)",
          des: "A traditional rice variety in the Philippines, Tapol has a dark color and is often used in local delicacies and desserts."
        },
        {
          _id: 3006,
          productName: "Italian Black Rice",
          des: "This medium-grain black rice is grown in Italy and has a nutty flavor. It is suitable for risotto and other dishes."
        },
        {
          _id: 3007,
          productName: "Javanese Black Rice",
          des: "Grown in Java, Indonesia, this variety has a rich, nutty flavor and is commonly used in traditional Indonesian cuisine."
        },
        {
          _id: 3008,
          productName: "Laotian Black Rice",
          des: "Grown in Laos, this black rice is often used in both sweet and savory dishes and is known for its distinctive color."
        }
        ],
        des: "Black rice, also known as Forbidden Rice, is a distinctive whole grain rice with a deep purple-black color, indicative of its high anthocyanin content. Renowned for its nutty taste and slightly sweet flavor, black rice offers a unique culinary experience along with added antioxidants, making it a healthful and visually appealing choice for various dishes.",
      },
      {
        _id: 2010,
        img: basmatirice,
        productName: "Basmati Rice",
        types: [{
          _id: 3001,
          productName: "Traditional Basmati",
          des: "The classic and most widely known variety, prized for its distinct aroma and long, slender grains."
        },
        {
          _id: 3002,
          productName: "Pusa Basmati",
          des: "A popular hybrid variety known for its long grains and aromatic flavor. Pusa Basmati rice varieties are developed to improve yield and disease resistance."
        },
        {
          _id: 3003,
          productName: "1121 Basmati",
          des: "This is a newer, high-yielding variety known for its extra-long grains. It's favored for its exquisite aroma and is widely used in biryanis and pilafs."
        },
        {
          _id: 3004,
          productName: "1509 Basmati",
          des: "Another newer variety with long grains, 1509 Basmati rice is known for its excellent cooking characteristics and aromatic qualities."
        },
        {
          _id: 3005,
          productName: "Taraori Basmati",
          des: "Grown in the Taraori region of Haryana, India, this variety is recognized for its fragrance and taste."
        },
        {
          _id: 3006,
          productName: "Ranbir Basmati",
          des: "Named after Maharaja Ranbir Singh, this variety is cultivated in the Jammu region of India."
        },
        {
          _id: 3007,
          productName: "Kasturi Basmati",
          des: "Known for its delightful fragrance, Kasturi Basmati rice is cultivated in the Himalayan region."
        }
        ],
        des: "Basmati rice is a long-grain rice known for its slender, aromatic grains and distinctive fragrance. Cultivated primarily in the Indian subcontinent, Basmati rice becomes fluffy and separates well when cooked. Renowned for its fragrant and nutty flavor, it is a popular choice in Indian and Middle Eastern cuisines, complementing a wide range of savory dishes.",
      },
    ]
  },
  {
    _id: 1002,
    img: saleImgTwo,
    productName: "Groundnuts & Walnuts ",
    price: "",
    color: "Groundnuts - Reddish-brown and Walnuts - brown ",
    badge: true,
    des: "Groundnuts, commonly known as peanuts, are legumes that develop underground. \n Nutritional Value in Groundnuts: Peanuts are rich in protein, healthy fats, vitamins, and minerals.\n  Walnuts are seeds of the Juglans regia tree, commonly known as the Persian or English walnut. \n Nutritional Value in walnut: Walnuts are a nutrient-dense food, rich in omega-3 fatty acids, antioxidants, vitamins (particularly vitamin E), and minerals. ",
    categ: [
      {
        _id: 2001,
        img: groundnuts,
        productName: "Groundnuts(peanuts)",
        types: [{
          _id: 3001,
          productName: "Runner Peanuts",
          des: "Runner peanuts are known for their uniform size and shape. They are often the preferred type for making peanut butter due to their high oil content.Runner peanuts are rich in monounsaturated and polyunsaturated fats, providing a good source of heart-healthy fats. They are also a good source of protein, fiber, and various vitamins and minerals."
        },
        {
          _id: 3002,
          productName: "Virginia Peanuts",
          des: "Virginia peanuts are larger in size compared to other varieties and are often referred to as ballpark or cocktail peanuts. They have a crunchy texture.Virginia peanuts offer similar nutritional benefits to runner peanuts, including being a good source of healthy fats, protein, and essential nutrients."
        },
        {
          _id: 3003,
          productName: "Spanish Peanuts",
          des: "Spanish peanuts are smaller in size and have a reddish-brown skin. They are commonly used in confectionery products.Spanish peanuts contain a good amount of protein and healthy fats. They are often used in candies, snacks, and trail mixes."
        },
        {
          _id: 3004,
          productName: "Valencia Peanuts",
          des: "Valencia peanuts have a small size and are often used for making boiled peanuts. They have three or more kernels per shell.Valencia peanuts are known for their sweet taste. They are a good source of protein, healthy fats, and various nutrients."
        },
        {
          _id: 3005,
          productName: "T-64 (Trg-64)",
          des: "T-64 is a popular groundnut variety cultivated in Maharashtra. It is known for its high pod yield and is suitable for both rainfed and irrigated conditions."
        },
        {
          _id: 3006,
          productName: "M-13 (Mandya-13)",
          des: "M-13 is another groundnut variety grown in Maharashtra. It is favored for its resistance to diseases and pests, making it a reliable choice for farmers."
        },
        {
          _id: 3007,
          productName: "AK-12-24",
          des: "AK-12-24 is a variety of groundnut that is suitable for both kharif and rabi seasons. It is known for its good yield potential and adaptability to different agro-climatic conditions."
        },
        {
          _id: 3008,
          productName: "TG-37A (Tag-37A)",
          des: "TG-37A is a groundnut variety with good yield potential. It is cultivated in Maharashtra and other regions with varying climatic conditions."
        },
        {
          _id: 3009,
          productName: "ICGV 91114 (ICGV)",
          des: "ICGV 91114 is a groundnut variety developed by the International Crops Research Institute for the Semi-Arid Tropics (ICRISAT). It is known for its tolerance to drought conditions and is suitable for cultivation in semi-arid regions like parts of Maharashtra."
        },
        {
          _id: 3010,
          productName: "JL 24 (Jawahar Lal Nehru-24)",
          des: "L 24 is a popular variety known for its high yield potential. It is suitable for both rainfed and irrigated conditions."
        },
        {
          _id: 3011,
          productName: "TMV 2 (Tagetes Minuta Variety 2)",
          des: "TMV 2 is a groundnut variety known for its resistance to certain diseases. It is cultivated in various regions of India."
        },
        {
          _id: 3012,
          productName: "TPT 4 (Tamil Nadu Pea Thrift-4)",
          des: "TPT 4 is a groundnut variety cultivated in Tamil Nadu and other states. It is known for its adaptability to different agro-climatic conditions."
        },
        {
          _id: 3013,
          productName: "GG 20 (Gujarat G 20)",
          des: "GG 20 is a groundnut variety developed in Gujarat. It is favored for its high yield potential and suitability for the region's agro-climatic conditions."
        },
        {
          _id: 3015,
          productName: "Kadiri 6 (KA 6)",
          des: "Kadiri 6 is a groundnut variety cultivated in Andhra Pradesh and Telangana. It is known for its resistance to certain diseases and pests."
        },
        {
          _id: 3016,
          productName: "GG 2 (Gujarat G 2)",
          des: "GG 2 is another groundnut variety developed in Gujarat. It is cultivated in various parts of India and is appreciated for its yield potential."
        },

        ],
        des: "Peanuts contain monounsaturated and polyunsaturated fats, which can contribute to heart health by lowering bad cholesterol levels.Groundnuts are a good source of protein, dietary fiber, vitamins (such as B vitamins), and minerals (including magnesium, phosphorus, and potassium).Peanuts contain antioxidants, such as resveratrol, which may help protect the body's cells from oxidative stress.Despite being calorie-dense, the combination of protein and fiber in peanuts can help promote satiety and contribute to weight management when consumed in moderation."
      },
      {
        _id: 2002,
        img: walnuts,
        productName: "English or Persian walnut ",
        types: [{
          _id: 3001,
          productName: "Chandler walnut",
          des: "Chandler is a popular variety known for its large-sized nuts and good kernel quality."
        },
        {
          _id: 3002,
          productName: "Serr walnut",
          des: "Serr is another widely grown variety, appreciated for its high yield and good nut quality."
        },
        {
          _id: 3003,
          productName: "Howard walnut",
          des: "Howard is known for its medium to large-sized nuts and is cultivated in various walnut-producing regions."
        }],
        des: "In India, the most commonly cultivated and commercially important type of walnut is the English or Persian walnut (Juglans regia). This walnut variety is often referred to as akhrot in Hindi and is widely grown in certain regions of the country. The English walnut is known for its thin-shell variety, making it easier to crack open and access the nutmeat inside.  There are several varieties of English walnuts cultivated in India. ",
      }
    ]

  },
  {
    _id: 1003,
    img: saleImgThree,
    productName: "Natural Honey ",
    price: "",
    color: "",
    badge: true,
    des: "Indulge in the unparalleled taste of our Pure Natural Honey, a golden elixir crafted by nature and harvested with care. \n  Our honey is a testament to purity, free from additives, preservatives, or any artificial substances. \n  Each drop is a direct gift from the bees to you. \n  Packed with antioxidants, enzymes, vitamins, and minerals, our honey is a natural energy booster and a wholesome addition to a healthy lifestyle.\n  Our honey is delicately packaged to preserve its freshness and distinctive characteristics.\n  The jar is designed not just to hold honey but to showcase its natural beauty.",
  }]

// Spices
export const currentSpices = [
  {
    _id: 1001,
    img: turmeric,
    productName: "Turmeric",
    price: "",
    color: "Yellow",
    des: "Known for its vibrant yellow color, turmeric has a slightly bitter and peppery flavor. \n It is a key ingredient in curry powder.	It is used extensively in Indian cooking. \n It is also often used as a colourant. It has a warm, peppery taste with musky, earthy undertones.\n \n Raw Turmeric, Dry Turmeric, Ground Turmeric",
    speci: [
      {
        _id: 101,
        _pur: "Type",
        Purity: "Raw Turmeric / Dry Turmeric / Ground Turmeric",
        _Natural: "Extraneous Foreign Matter (% by Weight)",
        Natural_Adm: "0.50% Max",
        _avg: "Whole Insects Dead",
        AverageGrain: "3 Max (By Count)",
        _Moisture: "Moisture",
        Moisture: "	12% Max",
        _BrokenGrain: "Excreta Mammalian (By mg./lb)",
        BrokenGrain: "5 mg/lb Max",
        _DamageGrain: "Excreta other (By mg./lb)",
        DamageGrain: "5 mg/lb Max",
        _ImmatureGrain: "Mold (% by wgt.)",
        ImmatureGrain: "3% Max",
        _ForeignMatter: "Insect defiled/ infested (% by wgt.)",
        ForeignMatter: "2.5% Max",
        _PackagingType: "Packaging Type",
        PackagingType: "Doypack,Cans, Glass Jar Packaging, Kraft Pouches,Cardboard Box Packaging,Sachet Packaging,Bulk Packaging,Institutional Packaging, Mono Carton Boxes [Inner layer Plain Poly Pouch]",
        _SupplyAbility: "Flavor",
        SupplyAbility: "Aromatic with a penetrating flavor",
        _MainExportMarket: "Main Export Market(S)",
        MainExportMarket: "United States, Netherlands, Bangladesh, United Kingdom United Arab Emirates,South Africa,Indonesia,	Japan and Germany",
        _DeliveryTime: "Delivery Time",
        DeliveryTime: "Approximately  15 Days"
      }
    ],
    categ: [
      {
        _id: 2001,
        img: ground,
        productName: "Ground Turmeric",
        des: " The most notable feature of ground turmeric is its vibrant yellow-orange color. This color is due to the presence of a compound called curcumin.Ground turmeric is a versatile spice used in a wide range of savory dishes, including curries, stews, soups, and rice dishes. It is also used in pickles, sauces, and beverages like golden milk.Turmeric is known for its potential health benefits, primarily attributed to curcumin. It has anti-inflammatory and antioxidant properties and is believed to have various health-promoting effects",
        types: [{
          _id: 3001,
          productName: "Regular Ground Turmeric",
          des: "This is the most common type available in most grocery stores. It is the standard ground turmeric powder obtained from the roots of the Curcuma longa plant."
        },
        {
          _id: 3002,
          productName: "Organic Turmeric",
          des: "Some people prefer organic products for various reasons, and organic ground turmeric is produced without the use of synthetic pesticides or fertilizers."
        },
        {
          _id: 3003,
          productName: "Turmeric from Specific Regions",
          des: "Turmeric is grown in various parts of the world, and the geographical origin can influence its flavor profile. For example, Indian turmeric is known for its bright color and robust, earthy flavor."
        },
        {
          _id: 3004,
          productName: "Wild Turmeric",
          des: "In some cases, wild or forest-grown turmeric is considered to have a different flavor profile compared to conventionally cultivated turmeric."
        },
        {
          _id: 3005,
          productName: "Curcumin-Enhanced Turmeric",
          des: "Curcumin is the active compound in turmeric responsible for its health benefits. Some ground turmeric products are marketed as having higher levels of curcumin for those seeking the potential health advantages."
        }
        ],
      },
      {
        _id: 2002,
        img: dry,
        productName: "Dry Turmeric ",
        des: "Dry turmeric generally refers to turmeric that has been dried and processed into a powder or in its whole form after the moisture content has been removed. Turmeric is a rhizomatous herbaceous perennial plant (Curcuma longa) belonging to the ginger family, and it is the dried rhizome (root) that is commonly used as a spice",
        types: [{
          _id: 3001,
          productName: "Regional Variations",
          des: "Turmeric is grown in different regions around the world, and the environmental conditions, soil, and climate can influence its flavor and aroma. For example, Indian turmeric is renowned for its vibrant color and robust, earthy flavor."
        },
        {
          _id: 3002,
          productName: "Organic and Conventional",
          des: "The choice between organic and conventionally grown turmeric is another factor. Organic turmeric is grown without synthetic pesticides or fertilizers, and some people prefer it for health and environmental reasons."
        },
        {
          _id: 3003,
          productName: "Curcumin Content",
          des: "Some products may highlight their curcumin content. Curcumin is the active compound in turmeric associated with various health benefits, and some varieties may have higher concentrations than others."
        }],
      },
      {
        _id: 2003,
        img: row,
        productName: "Raw Turmeric ",
        des: "Raw turmeric refers to the fresh, unprocessed rhizomes (underground stems) of the Curcuma longa plant before they are dried or ground into powder. It is the raw form of the spice that is widely used in various cuisines and traditional medicine.",
        types: [{
          _id: 3001,
          productName: "Indian Turmeric",
          des: "India is a major producer of turmeric, and different regions within the country may produce turmeric with varying flavors and aromas. For example, Alleppey turmeric from Kerala is known for its high curcumin content and bright color."
        },
        {
          _id: 3002,
          productName: "Hawaiian Turmeric",
          des: "Turmeric grown in Hawaii is known for its unique flavor and is used in local cuisine and traditional practices."
        },
        {
          _id: 3003,
          productName: "Medicinal Turmeric",
          des: "Certain varieties may be specifically cultivated for their potential health benefits, emphasizing higher concentrations of compounds like curcumin."
        },
        {
          _id: 3004,
          productName: "Yellow Turmeric",
          des: "The most common type with a bright yellow color."
        },
        {
          _id: 3005,
          productName: "Orange Turmeric",
          des: "Some varieties may have a more orange hue."
        }
        ]
      }

    ]
  },
  {
    _id: 1002,
    img: corianderpowderleaf,
    productName: "Coriander ",
    price: "",
    color: "Yellowish-brown to tan",
    des: "Coriander, scientifically known as Coriandrum sativum, is both an herb and a spice widely used in culinary applications around the world. \n The fresh leaves of the coriander plant are known as cilantro. \nCoriander is believed to have antioxidant properties and may offer digestive benefits and coriander may have anti-inflammatory and antimicrobial properties.",
    speci: [
      {
        _id: 101,
        _pur: "Type",
        Purity: "Seed Whole, Splits and Coriander Powder",
        _Natural: "Laudability",
        Natural_Adm: "9.5 Metric Tonnes",
        _avg: "Purity",
        AverageGrain: "99% Min.",
        _Moisture: "Moisture",
        Moisture: "12-14% Max",
        _BrokenGrain: "Flavor",
        BrokenGrain: "Aromatic",
        _DamageGrain: "Admixture",
        DamageGrain: "2% Max",
        _ImmatureGrain: "Immature Seed",
        ImmatureGrain: "3% Max",
        _ForeignMatter: "Broken",
        ForeignMatter: "2.5% Max",
        _PackagingType: "Packing",
        PackagingType: "40/ 50 KG PP Bags/ Jute Bags",
        _SupplyAbility: "Total Ash",
        SupplyAbility: "9% Max",
        _MainExportMarket: "Main Export Market(S)",
        MainExportMarket: "United States, Ukraine, Morocco, Argentina, Italy, Pakistan, UAE, United Kingdom, Vietnam",
        _DeliveryTime: "Delivery Time",
        DeliveryTime: "Approximately  15 Days"
      }
    ],
    categ: [
      {
        _id: 2001,
        img: corinaderpowder,
        productName: "Coriander Seeds types",
        des: "Coriander is one of the oldest herbs and spices. The coriander plant's fruit contains two seeds, which are dried and used as dried spices. When fully ripe, the seeds are yellowish-brown to tan in color, with longitudinal ridges. The flavor of coriander seeds is light and pleasant, with a hint of citrus. It has a rich, warming scent that is slightly fruity and nutty. Coriander seeds are available all year in whole or powdered powder form.",
        types: [{
          _id: 3001,
          productName: "Badami",
          des: "Badami quality is the cheapest coriander quality available in the market, Spices Manufacturing companies use it in the grinding and spices blending process. As the Spice Manufacturers look for Aroma caring little about the color of the product.Badami coriander seed is used in retail packing also for Price-conscious market."
        },
        {
          _id: 3002,
          productName: "Eagle",
          des: "Eagle quality coriander is one of the cheapest coriander quality available in the market, Spices Manufacturing companies use it in the grinding and spices blending process. As the Spice Manufacturers look for Aroma caring little about the color of the product.Spices Manufacturing companies use Coriander Eagle in the grinding and spices blending process. As the Spice Manufacturers look for Aroma caring little about the color of the product."
        },
        {
          _id: 3003,
          productName: "Scooter",
          des: "Light Green Shade, mildly strong in aroma, Some seeds of the light brown present, Used in grinding pure coriander powder, due to its green color, Store it in cold storage to preserve its aroma and green color."
        },
        {
          _id: 3004,
          productName: "Parrot",
          des: "Green shade, strong in aroma. Used in grinding pure coriander powder, due to its green color. Store it in cold storage to preserve its aroma and green color and Fumigate every 2 months, as coriander is prone to infestation"
        },
        {
          _id: 3005,
          productName: "Coriander Split",
          des: "The split husk of coriander seeds. It is the cheapest to use in powder (grounded) spices."
        }
        ]
      },
      {
        _id: 2002,
        img: corinaderleaves,
        productName: "Coriander Leaves",
        des: "Coriander leaves, also known as cilantro, come from the Coriandrum sativum plant.Cilantro leaves have a distinctive fresh and citrusy flavor. They add brightness to dishes and are often described as having a slightly tangy and herbaceous taste.Cilantro is a versatile herb used in a variety of cuisines, including Mexican, Indian, Thai, and Middle Eastern. It is commonly used in salads, salsas, chutneys, curries, and garnishes for soups and stews.",
        types: [{
          _id: 3001,
          productName: "Standard Cilantro",
          des: "This is the most common type found in grocery stores and markets. It has the typical fresh, citrusy flavor and lacy appearance. The leaves are bright green and are widely used in various cuisines."
        }],
      }
    ]

  },
  {
    _id: 1003,
    img: kashmirichillipwdr,
    productName: "Red Chilli Powder",
    price: "",
    color: "Red",
    des: "Red chili powder is a spice derived from ground, dried red chili peppers. \n It is a popular and widely used spice in many cuisines around the world, known for its ability to add heat, flavor, and vibrant color to dishes.\n Red chili powder imparts a pungent and spicy flavor to dishes. The flavor can range from mild and slightly sweet to extremely hot, depending on the variety of chili peppers used.The heat of red chili powder is measured on the Scoville Heat Scale. \n Red chili powder is used in a variety of dishes, such as curries, stews, soups, sauces, and spice rubs for meats. \n Red chili powder may have health benefits, including boosting metabolism and acting as an antioxidant.",
    speci: [
      {
        _id: 101,
        _pur: "Botanical Name",
        Purity: "Capsicum Frutescens",
        _Natural: "Usage",
        Natural_Adm: "Conventional",
        _avg: "Type",
        AverageGrain: "Machine Clean & Sortex Clean",
        _Moisture: "Scoville heat unit (SHU)",
        Moisture: "8,000 to 1,00,000 SHU",
        _BrokenGrain: "Size",
        BrokenGrain: "40-65 Mesh",
        _DamageGrain: "Colour",
        DamageGrain: "45-55 / 55-65 / 65-75 ASTA",
        _ImmatureGrain: "Aflatoxin",
        ImmatureGrain: "Below 10 PPB Or As Required",
        _ForeignMatter: "Storage Condition",
        ForeignMatter: "Cool And Dry (20 C)",
        _PackagingType: "Packaging Type",
        PackagingType: "In New PP Bags lined with polyethene Or Paper Bags",
        _SupplyAbility: "Origin",
        SupplyAbility: "India",
        _MainExportMarket: "Main Export Market(S)",
        MainExportMarket: "United States, Netherlands, Bangladesh, United Kingdom United Arab Emirates,South Africa,Indonesia,	Japan and Germany",
        _DeliveryTime: "Delivery Time",
        DeliveryTime: "Approximately  10 Days"
      }
    ],
    categ: [
      {
        _id: 2001,
        img: redchillipowder,
        productName: "Red Chilli Powder",
        des: "When using red chili powder in recipes, it's essential to start with a small amount and adjust according to your desired level of heat. Keep in mind that the intensity can vary among different brands and types of red chili powder. When choosing a red chili powder, consider the specific variety, heat preference, and the culinary traditions of the cuisine you are preparing.",
        types: [{
          _id: 3001,
          productName: "Kashmiri Red Chili Powder",
          des: "This type of chili powder is known for its deep red color and moderate heat. It is commonly used in Indian cuisine, particularly in dishes like tandoori chicken and various curries."
        },
        {
          _id: 3002,
          productName: "Paprika",
          des: "Paprika is a spice made from dried and ground bell peppers or chili peppers. It comes in various types, such as sweet, mild, and hot. Paprika is widely used in European and Mediterranean cuisines, adding both color and flavor to dishes."
        },
        {
          _id: 3003,
          productName: "Ancho Chili Powder",
          des: "Ancho peppers are dried poblano peppers, and the powder derived from them is known for its mild, sweet, and smoky flavor. It is a common ingredient in Mexican cuisine, used in sauces, stews, and marinades."
        },
        {
          _id: 3004,
          productName: "Cayenne Pepper",
          des: "This is one of the most popular types of red chili powder. Cayenne pepper is known for its moderate heat and vibrant red color. It is commonly used in a variety of dishes, including soups, sauces, and spice blends."
        },
        {
          _id: 3005,
          productName: "Chipotle Chili Powder",
          des: "Chipotle peppers are smoke-dried jalapeño peppers, and the powder has a smoky and moderately spicy flavor. It's often used in Mexican and Southwestern cuisines to add a distinctive smokiness to dishes."
        }
          ,
        {
          _id: 3006,
          productName: "Aleppo Pepper",
          des: "This is a type of red chili powder originating from the Aleppo region in Syria. It has a moderate heat level and imparts a fruity, slightly sweet flavor. It is commonly used in Middle Eastern and Mediterranean cuisines."
        }
          ,
        {
          _id: 3007,
          productName: "Thai Chili Powder",
          des: "Made from small, hot Thai chili peppers, this powder adds intense heat and flavor to Thai dishes. It is commonly used in Thai curries, sauces, and stir-fries."
        }
          ,
        {
          _id: 3008,
          productName: "Gochugaru",
          des: "This is a Korean red chili powder made from sun-dried red chili peppers. It is a key ingredient in Korean cuisine, especially in making kimchi and other spicy dishes."
        }
        ],
      },
      {
        _id: 2002,
        img: dryredchilli,
        productName: "Dry Red chilli ",
        des: "Dry red chili is a spice obtained from drying and grinding red chili peppers. These peppers belong to various species and varieties, and the dried form of these peppers is widely used in culinary applications around the world. Dry red chilies typically have a wrinkled and shriveled appearance. The color can vary from bright red to deep red or even dark brown, depending on the specific variety and the drying process.",
        types: [{
          _id: 3001,
          productName: "S17/Teja",
          des: "Nearly, 50% of the crop grown in Guntur comprises the Teja variety, because of the huge demand for it. Its massive share can also be attributed to the fact that Teja has high resistance to pests and viruses, because of innate pungency. Its output and prices are higher than all other varieties, though not as high as the hybrid ones."
        },
        {
          _id: 3002,
          productName: "334/S4/Guntur Sannam",
          des: "The original desi variety was from the town Virudhunagar, Tamilnadu State."
        },
        {
          _id: 3003,
          productName: "355/ Bydagi Variety",
          des: "Wrinkled variety deep red or dark red in colour, mild pungent. Grown in the fertile lands of Karnataka, India. Bellary Hubli & Bydagi areas of Karnataka."
        }
        ],
      }
    ]
  },
  {
    _id: 1004,
    img: cuminseed,
    productName: "Cumin",
    price: "",
    color: "Yellow-Brown",
    des: "Cumin (Cuminum cyminum) is a flowering plant belonging to the Apiaceae family, which is native to the eastern Mediterranean region and southwestern Asia. \n It has been cultivated for thousands of years and is valued for both its culinary and medicinal properties.\n Cumin is a small, slender plant with feathery leaves and tiny white or pink flowers.Cumin is a staple spice in many cuisines around the world, particularly in Indian, Middle Eastern, and Mexican dishes. \n Cumin is not only valued for its culinary uses but also for its potential health benefits. It contains antioxidants and has been traditionally used in herbal medicine for its digestive properties. ",
    speci: [
      {
        _id: 101,
        _pur: "Purity",
        Purity: "99.50%/99%/98% & 97%",
        _Natural: "Type",
        Natural_Adm: "Machine Cleaned/ Sortex Cleaned/ Extra Bold",
        _avg: "Origin",
        AverageGrain: "India",
        _Moisture: "Moisture",
        Moisture: "12.0 % Max",
        _BrokenGrain: "Extraneous Matter (m/m)",
        BrokenGrain: "1.0 % Max",
        _DamageGrain: "Excreta Mammalian(By Mg./Lb)",
        DamageGrain: "3 Mg/Lb Max",
        _ImmatureGrain: "Insect Damaged, Discoloured, Weevilled, Immature Seeds",
        ImmatureGrain: "1 % Max",
        _ForeignMatter: "Mold ( By Wgt.)",
        ForeignMatter: "1 % Max",
        _PackagingType: "Total Ash  (m/m)",
        PackagingType: "9.5 % Max.",
        _SupplyAbility: "Acid Insoluble Ash (m/m)",
        SupplyAbility: "1.5 % Max.",
        _MainExportMarket: "Main Export Market(S)",
        MainExportMarket: "China, Turkey, United States, Netherlands, Bangladesh, United Kingdom United Arab Emirates,South Africa,Indonesia,	Japan and Germany",
        _DeliveryTime: "Delivery Time",
        DeliveryTime: "Approximately  10 Days"
      }
    ],
    categ: [
      {
        _id: 2001,
        img: wholecuminseeds,
        productName: "Whole Cumin Seeds",
        des: "These are the entire seeds of the cumin plant. They are often used in pickling, spice blends, and some recipes where a slightly milder flavor and less powdery texture are desired. Whole cumin seeds can be toasted or ground as needed.",
      },
      {
        _id: 2002,
        img: groundcumin,
        productName: "Ground Cumin",
        des: "The most common form of cumin found in kitchens. Ground cumin is made by grinding the seeds into a powder. It is a convenient option for easily incorporating cumin flavor into dishes. Ground cumin is a key ingredient in many spice blends.",
      },
      {
        _id: 2003,
        img: cumintoastedwhole,
        productName: "Toasted Cumin",
        des: "To enhance the flavor of cumin, some recipes call for toasting the seeds before use. This process involves dry-roasting the seeds in a pan until they release their aromatic oils, resulting in a richer and more intense flavor. Toasted cumin is often used in Indian and Middle Eastern cuisines.",
      },
      {
        _id: 2004,
        img: cuminPowder,
        productName: "Cumin Powder",
        des: "This is essentially ground cumin but may sometimes refer to a finer powder. It's widely used in spice mixes, rubs, and as a standalone seasoning in various cuisines.",
      },
      {
        _id: 2005,
        img: blackcumin,
        productName: "Black Cumin (Nigella sativa)",
        des: "Also known as black seed or black caraway, black cumin is a different plant species than Cuminum cyminum. It has a distinct flavor and is used in some regional cuisines. It's essential to differentiate between black cumin and regular cumin in recipes.",
      },
      {
        _id: 2006,
        img: cumminwater,
        productName: "Jeera (Cumin) Water",
        des: "In traditional Ayurvedic and herbal medicine, cumin water is made by soaking cumin seeds in water overnight. This water is believed to have various health benefits and is sometimes consumed for its digestive properties.",
      }
    ]
  }
]

// Spices
export const currentCereal = [
  {
    _id: "100001",
    img: maize,
    productName: "MAIZE",
    // price="3.03"
    color: "Yellow",
    // badge={true}
    // size="30 mm to 50 mm and 50 mm to 60 mm"
    packaging: "Plastic bags which may be laminated or provided with a lose liner bag and multiwall paper sacks with a plastic liner bag",
    des: "Maize, commonly known as corn, is a cereal grain that belongs to the grass family Poaceae. \n It is one of the most widely cultivated and important crops globally, serving as a staple food for numerous populations and finding use in various industries. \n Varieties - Sweet Corn, Field Corn,Flint Corn,Popcorn"
  },
  {
    _id: "100002",
    img: rye,
    productName: "Rye",
    // price:",3.70",
    color: "Light to medium brown color",
    size: "5-7 millimeters (0.2-0.3 inches) in length",
    packaging: "Common packaging materials include woven polypropylene bags, jute bags, or paper bags with inner liners.",
    des: "Rye (Secale cereale) is a cereal grain belonging to the grass family Poaceae.\n It is an annual plant that is widely cultivated for its grains, primarily used as a staple food and in the production of various food products and alcoholic beverages.\n Varieties - Winter Rye , Spring Rye",

  },
  {
    _id: "100003",
    img: barley,
    productName: "Barley",
    // price:"2.03"
    color: "Golden to Black",
    // badge:{false}
    size: "Short as 0.16–0.28 in (4–7 mm) to as long as 0.47–0.59 in (12–15 mm) ",
    packaging: "Polypropylene bags, jute bags, or paper bags with inner liners",
    des: "Barley is high in fiber, especially beta-glucan, which may reduce cholesterol and blood sugar levels.\n It may also aid weight loss and improve digestion.\n Whole-grain, hulled barley is more nutritious than refined, pearled barley.",
  },
  {
    _id: "100004",
    img: oat,
    productName: "Oat",
    // price:"0.00"
    color: "Creamy white – white to light brown",
    // badge:{false}
    size: "0,9-1,1 mm",
    packaging: "Paper Box, Plastic Packet",
    des: "Oats, formally named Avena sativa, is a type of cereal grain from the Poaceae grass family of plants. \n The grain refers specifically to the edible seeds of oat grass, which is what ends up in our breakfast bowls.",
  },
  {
    _id: "100005",
    img: greatmillet,
    productName: "Great Millet",
    // price:"0.00"
    color: "red, white, yellow or gold",
    // badge:{false}
    size: "1-2 mm in diameter,  3-4 mm length",
    packaging: "Packaged in containers which will safeguard the hygienic, nutritional, technological, and organoleptic qualities of the product",
    des: "While millet is not a major food crop in the developed world, it plays a vital role in the diets of many people in developing countries. \n Millet is a drought-tolerant crop that can be grown in dry, arid climates where other crops would fail. \n It is also a nutritious grain that is high in fiber and essential minerals.",
  },
  {
    _id: "100006",
    img: buckwheat,
    productName: "Buckwheat",
    // price:"0.00"
    color: "White with small dark brown/black speckles",
    // badge:{false}
    size: "4-5mm",
    packaging: "10kg, 20kg, 30kg, 50kg PP Bags",
    des: "Buckwheat is a rich source of flavonoids such as rutin, isoorientin, quercetin, isovitexin, vitexin, and orientin. \n Among all pseudocereals, rutin is only present in buckwheat, with higher antioxidant, anti‐inflammation, and anticancer properties",
  },
  {
    _id: "100007",
    img: wheat,
    productName: "Wheat",
    // price:"0.00"
    color: "Light Yellow",
    size: "3.37 mm",
    packaging: "BULK, BAG, CONTAINERIZED",
    des: "Major source of starch and energy, wheat also provides substantial amounts of a number of components which are essential or beneficial for health, notably protein, vitamins (notably B vitamins), dietary fiber, and phytochemicals.",
  }
]